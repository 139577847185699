import React, { useState } from 'react';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import PIXImage from '../assets/PIX.png';


interface FooterProps {
  openEmailPopup: () => void; // Define a prop para abrir o popup de email
}


export default function Footer({ openEmailPopup }: FooterProps) {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const currentYear = new Date().getFullYear();
  const PIX_KEY = "pix.dailybible@gmail.com";

  const copyPIXKey = async () => {
    try {
      await navigator.clipboard.writeText(PIX_KEY);
      setShowCopiedMessage(true);
      setTimeout(() => setShowCopiedMessage(false), 2000);
    } catch (err) {
      console.error('Erro ao copiar chave PIX:', err);
    }
  };

  return (
    <footer className="bg-gray-900 text-gray-400 py-8 mt-auto opacity-85">
    {/* <footer className=""> */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Coluna da esquerda (Sobre + Links) */}
          <div className="lg:col-span-7 flex flex-col space-y-8">
            {/* Sobre */}
            <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
              <h3 className="text-white text-lg font-semibold mb-2">
                Sobre o DailyBible
              </h3>
              <p className="text-sm max-w-md">
                Um jogo diário que desafia seu conhecimento bíblico de forma divertida e educativa.
              </p>
            </div>

            {/* Links e Contato */}
            <div className="flex flex-col items-center lg:items-start">
              <h3 className="text-white text-lg font-semibold mb-2">
                Links e Contato
              </h3>
              <div className="flex flex-col items-center lg:items-start space-y-2">
                <button
                  onClick={() => setShowPrivacyPolicy(true)}
                  className="text-sm hover:text-white transition-colors inline-flex items-center gap-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                  Política de Privacidade
                </button>

                {/* Redes Sociais */}
                <div className="flex space-x-4 mt-1">
                  <a
                    onClick={openEmailPopup}
                    className="hover:text-white transition-colors cursor-pointer"
                    title="Contato"
                    aria-label="Enviar email"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </a>
                  <a
                    href="https://github.com/Thales-Melo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-white transition-colors"
                    title="GitHub"
                    aria-label="Visitar GitHub"
                  >
                    <svg height="24" width="24" viewBox="0 0 16 16" className="w-6 h-6">
                      <path fill="currentColor" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"/>
                    </svg>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/thales-penha/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-white transition-colors"
                    title="LinkedIn"
                    aria-label="Visitar LinkedIn"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Coluna da direita (PIX) */}
          <div className="lg:col-span-5 flex flex-col items-center">
            <h3 className="text-white text-lg font-semibold mb-2">
              Apoie o Projeto
            </h3>
            <p className="text-sm text-center mb-2">
              Leia o QR Code ou clique no botão abaixo para copiar a chave PIX
            </p>
            <div className="relative">
              <img 
                src={PIXImage} 
                alt="QR Code PIX" 
                className="w-32 h-32 mb-2 cursor-pointer hover:opacity-90 transition-opacity" 
                onClick={copyPIXKey}
              />
              {showCopiedMessage && (
                <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-3 py-1 rounded-full text-sm whitespace-nowrap">
                  Chave PIX copiada!
                </div>
              )}
            </div>
            <button
              onClick={copyPIXKey}
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full transition-colors duration-200 flex items-center space-x-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
              </svg>
              <span>Chave PIX: pix.dailybible@gmail.com</span>
            </button>
            <p className="text-sm text-center max-w-xs mt-2">
              Faça uma contribuição via PIX para ajudar a manter o DailyBible no ar!
            </p>
          </div>
        </div>

        {/* Linha de copyright */}
        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-sm">
          <p>&copy; {currentYear} DailyBible. Desenvolvido por Thales Melo. Todos os direitos reservados.</p>
        </div>
      </div>

      <PrivacyPolicyModal
        isOpen={showPrivacyPolicy}
        onClose={() => setShowPrivacyPolicy(false)}
      />
    </footer>
  );
} 