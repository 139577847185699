import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ABIBLIA_DIGITAL_TOKEN, DEFAULT_ACCESS_TOKEN } from '../config';
import GameResultPopup from './GameResultPopup';
import TutorialPopup from './TutorialPopup';
import GameAlertPopUp from './GameAlertPopUp';
import { Tooltip } from 'react-tooltip';
import { motion, AnimatePresence } from 'framer-motion';
import { APP_API_URL } from '../config';
import CouponPopup from './CouponPopup';
import PartnershipCard from './PartnershipCard';
import LoginSuggestionCard from './LoginSuggestionCard';

type GuessStage = 'book' | 'chapter' | 'verse' | 'version';


interface ResponseData {
  correct: boolean;
  points?: number;
}

export interface DailyVerse {
  id: number;
  livro_nome: string;
  livro_abreviacao_pt: string;
  livro_abreviacao_en: string;
  capitulo: number;
  numero: number;
  texto: string;
  versao: string;
  autor: string;
  grupo: string;
  testamento: string;
  data_sorteio: string;
  timestamp: string;
  force_update?: boolean;
  chapter_hint?: string;
  devocional?: string;
}

interface DailyVerseResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: DailyVerse[];
}

interface Book {
  name: string;
  abbrev: { pt: string; en: string };
}

interface BookInfo {
  abbrev: { pt: string; en: string };
  author: string;
  chapters: number;
  comment: string;
  group: string;
  name: string;
  testament: string;
}

interface VersesResponse {
  verses: { number: number }[];
}

interface Version {
  version: string;
  verses: number;
}

interface HintBlock {
  guessedBook: string;
  group: string;
  author: string;
  chapters: number;
  testament: string;
  isCorrect: boolean;
}

interface VerseAttempt {
  guess: number;
  status: 'correct' | 'close' | 'incorrect';
  direction?: 'up' | 'down';
}

interface ChapterAttempt {
  guess: number;
  status: 'correct' | 'close' | 'incorrect';
  direction?: 'up' | 'down';
}

interface VersionAttempt {
  guess: string;
  status: 'correct' | 'incorrect';
}

interface VerseHintBarProps {
  attempt: VerseAttempt;
}

interface ChapterHintBarProps {
  attempt: ChapterAttempt;
}

interface VersionHintBarProps {
  attempt: VersionAttempt;
}

/**
 * Normaliza uma string removendo acentos e caracteres especiais, e convertendo para minúsculas.
 * @param str - A string a ser normalizada.
 * @returns A string normalizada.
 */
function normalizeString(str: string): string {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // remove acentos
    .replace(/[ªº°]/g, "") // remove caracteres especiais como ª, º, °
    .replace(/[^a-zA-Z0-9\s]/g, "") // mantém apenas letras, números e espaços
    .toLowerCase()
    .trim();
}

/**
 * Verifica se o momento atual está dentro do horário de manutenção.
 * @returns `true` se estiver em horário de manutenção, caso contrário `false`.
 */
const isMaintenanceTime = (): boolean => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();

  return (hours === 23 && minutes === 59) || 
         (hours === 0 && minutes <= 1);
};

/**
 * Componente de página de manutenção.
 * @returns Elemento JSX da página de manutenção.
 */
const MaintenancePage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center">
      <h1 className="text-2xl font-bold mb-4">Em Manutenção</h1>
      <p>Estamos atualizando o versículo do dia.</p>
      <p>Por favor, retorne em alguns minutos.</p>
    </div>
  );
};

/**
 * Componente principal do jogo.
 * @returns Elemento JSX do jogo.
 */
export default function Game() {
  const [verse, setVerse] = useState('');
  const [guess, setGuess] = useState('');
  const [score, setScore] = useState(50);
  const [stage, setStage] = useState<GuessStage>('book');
  const [feedback, setFeedback] = useState<string>('');
  const [correctGuesses, setCorrectGuesses] = useState({
    book: '',
    chapter: '',
    verse: '',
    version: ''
  });
  const [bookOptions, setBookOptions] = useState<string[]>([]);
  const [chapterOptions, setChapterOptions] = useState<string[]>([]);
  const [verseOptions, setVerseOptions] = useState<string[]>([]);
  const [versionOptions, setVersionOptions] = useState<string[]>([]);
  const [books, setBooks] = useState<string[]>([]);
  const [bookAbbrevs, setBookAbbrevs] = useState<string[]>([]);
  const [chapters, setChapters] = useState<number[]>([]);
  const [verses, setVerses] = useState<number[]>([]);
  const [versions, setVersions] = useState<string[]>([]);
  const [completedStages, setCompletedStages] = useState<GuessStage[]>([]);
  const [gameFinished, setGameFinished] = useState(false);
  const [bookInfo, setBookInfo] = useState<BookInfo | null>(null);
  const [dailyVerse, setDailyVerse] = useState<DailyVerse | null>(null);
  const [triedOptions, setTriedOptions] = useState<Set<string>>(new Set());
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [hintBlocks, setHintBlocks] = useState<HintBlock[]>([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(-1);
  const optionsRef = useRef<HTMLUListElement>(null);
  const [keyPressInterval, setKeyPressInterval] = useState<NodeJS.Timeout | null>(null);
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [stageAttempts, setStageAttempts] = useState({
    book: 0,
    chapter: 0,
    verse: 0,
    version: 0
  });
  const [showResultPopup, setShowResultPopup] = useState(false);
  const [timeUntilMidnight, setTimeUntilMidnight] = useState<string>('');
  const [showTutorial, setShowTutorial] = useState(false);
  const [showGameAlert, setShowGameAlert] = useState(false);
  const [chapterHint, setChapterHint] = useState<string>('');
  const [showHintButton, setShowHintButton] = useState(false);
  const [hintAvailable, setHintAvailable] = useState(false);
  const [attemptsUntilHint, setAttemptsUntilHint] = useState(3);
  const [isHintVisible, setIsHintVisible] = useState(false);
  const [verseAttempts, setVerseAttempts] = useState<VerseAttempt[]>([]);
  const [chapterAttempts, setChapterAttempts] = useState<ChapterAttempt[]>([]);
  const [versionAttempts, setVersionAttempts] = useState<VersionAttempt[]>([]);
  
  const token = localStorage.getItem('token');
  const isAuthenticated = !!token && token !== DEFAULT_ACCESS_TOKEN;

  // Temporários
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [showSelectedCouponPopup, setShowSelectedCouponPopup] = useState(false);
  const [coupon, setCoupon] = useState<{ codigo: string; loja: string; valor: number; tipo: string } | null>(null);
  const [coupons, setCoupons] = useState<{ codigo: string; loja: string; valor: number; tipo: string }[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<{ codigo: string; loja: string; valor: number; tipo: string } | null>(null);
  const [showLoginSuggestion, setShowLoginSuggestion] = useState(!isAuthenticated);
  
  useEffect(() => {
    // printar no console a url da api
    if (isMaintenanceTime()) {
      return;
    }
    fetchDailyVerse();
    clearStoredVerseAtMidnight();
    fetchBooks();
    fetchVersions();
  }, []);

  useEffect(() => {
    if (!gameFinished) return;

    const timer = setInterval(() => {
      const now = new Date();
      const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const diff = midnight.getTime() - now.getTime();

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeUntilMidnight(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    }, 1000);

    return () => clearInterval(timer);
  }, [gameFinished]);

  /**
   * Lida com as tentativas de adivinhação de versículo.
   * @param guessedNumber - Número do versículo adivinhado.
   */
  const handleVerseGuess = (guessedNumber: number) => {
    if (!dailyVerse) return;

    const realNumber = dailyVerse.numero;
    const difference = guessedNumber - realNumber;
    let status: 'correct' | 'close' | 'incorrect';
    let direction: 'up' | 'down' | undefined;

    if (guessedNumber === realNumber) {
      status = 'correct';
    } else if (Math.abs(difference) <= 2) {
      status = 'close';
    } else {
      status = 'incorrect';
    }

    if (difference > 0) {
      direction = 'down';
    } else if (difference < 0) {
      direction = 'up';
    }

    const newAttempt: VerseAttempt = {
      guess: guessedNumber,
      status,
      direction,
    };

    setVerseAttempts(prev => [...prev, newAttempt]);
  };

  /**
   * Lida com as tentativas de adivinhação de capítulo.
   * @param guessedNumber - Número do capítulo adivinhado.
   */
  const handleChapterGuess = (guessedNumber: number) => {
    if (!dailyVerse) return;

    const realNumber = dailyVerse.capitulo;
    const difference = guessedNumber - realNumber;
    let status: 'correct' | 'close' | 'incorrect';
    let direction: 'up' | 'down' | undefined;

    if (guessedNumber === realNumber) {
      status = 'correct';
    } else if (Math.abs(difference) <= 2) {
      status = 'close';
    } else {
      status = 'incorrect';
    }

    if (difference > 0) {
      direction = 'down';
    } else if (difference < 0) {
      direction = 'up';
    }

    const newAttempt: VerseAttempt = {
      guess: guessedNumber,
      status,
      direction,
    };

    setChapterAttempts(prev => [...prev, newAttempt]);
  };

  const handleVersionGuess = (guessedVersion: string) => {
    if (!dailyVerse) return;

    const realVersion = dailyVerse.versao.toUpperCase();
    const normalizedGuess = guessedVersion.toUpperCase();
    const isCorrect = normalizedGuess === realVersion || 
      (realVersion === 'RA' && normalizedGuess === 'ARA') || 
      (realVersion === 'ARA' && normalizedGuess === 'RA');

    const newAttempt: VersionAttempt = {
      guess: guessedVersion.toUpperCase(),
      status: isCorrect ? 'correct' : 'incorrect'
    };

    setVersionAttempts(prev => [...prev, newAttempt]);
  };

  /**
   * Busca o versículo diário da API.
   */
  const fetchDailyVerse = async () => {
    const token = localStorage.getItem('token') || DEFAULT_ACCESS_TOKEN;
    
    try {
      const response = await axios.get<DailyVerse>(
        `${APP_API_URL}/api/get_daily_verse/`,
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );
      
      if (response.data) {
        const verseData = response.data;
        
        setDailyVerse(verseData);
        setVerse(verseData.texto);
        setCorrectGuesses({
          book: verseData.livro_nome,
          chapter: verseData.capitulo.toString(),
          verse: verseData.numero.toString(),
          version: verseData.versao
        });
        
        fetchBookInfo(verseData.livro_abreviacao_pt);
      }
    } catch (error) {
      console.error('Falha ao buscar o versículo diário:', error);
    }

    if (token) {
      fetchUserCoupons(token);
    }

  };

  /**
   * Busca informações detalhadas do livro baseado na abreviação.
   * @param abbrev - Abreviação do livro.
   */
  const fetchBookInfo = async (abbrev: string) => {
    try {
      const response = await axios.get<BookInfo>(`https://www.abibliadigital.com.br/api/books/${abbrev}`, {
        headers: {
          'Authorization': `Bearer ${ABIBLIA_DIGITAL_TOKEN}`
        }
      });
      setBookInfo(response.data);
      fetchChapters(response.data.chapters);
    } catch (error) {
      console.error('Falha ao buscar informações do livro:', error);
    }
  };

  /**
   * Busca a lista de livros disponíveis.
   */
  const fetchBooks = async () => {
    try {
      const response = await axios.get<Book[]>('https://www.abibliadigital.com.br/api/books', {
        headers: {
          'Authorization': `Bearer ${ABIBLIA_DIGITAL_TOKEN}`
        }
      });
      const livros = response.data.map(livro => livro.name.toUpperCase());
      const abbrevs = response.data.map(livro => livro.abbrev.pt);
      setBooks(livros);
      setBookOptions(livros);
      setBookAbbrevs(abbrevs);
    } catch (error) {
      console.error('Falha ao buscar os livros:', error);
    }
  };

  /**
   * Obtém a abreviação do livro com base no nome.
   * @param book - Nome do livro.
   * @returns Abreviação do livro.
   */
  const getBookAbbrev = (book: string) => {
    const index = books.indexOf(book);
    return bookAbbrevs[index];
  };

  /**
   * Busca e configura os capítulos disponíveis.
   * @param totalChapters - Número total de capítulos no livro.
   */
  const fetchChapters = (totalChapters: number) => {
    const chapters = Array.from({ length: totalChapters }, (_, i) => (i + 1).toString());
    setChapters(chapters.map(Number));
    setChapterOptions(chapters);
  };

  /**
   * Busca os versículos disponíveis para o capítulo atual.
   */
  const fetchVerses = async () => {
    if (!dailyVerse) {
      console.error('Versículo diário não disponível');
      return;
    }

    try {
      const response = await axios.get<VersesResponse>(`https://www.abibliadigital.com.br/api/verses/${dailyVerse.versao}/${dailyVerse.livro_abreviacao_pt}/${dailyVerse.capitulo}`, {
        headers: {
          'Authorization': `Bearer ${ABIBLIA_DIGITAL_TOKEN}`
        }
      });
      
      if (response.data && response.data.verses) {
        const verses = response.data.verses.map(verse => verse.number.toString());
        setVerses(verses.map(Number));
        setVerseOptions(verses);
      } else {
        throw new Error('Dados de versículos inválidos');
      }
    } catch (error) {
      console.error('Falha ao buscar os versículos:', error);
    }
  };
  
  /**
   * Configura as versões disponíveis.
   */
  const fetchVersions = async () => {
    setVersions(['NVI', 'ACF', 'ARA']);
    setVersionOptions(['NVI', 'ACF', 'ARA']);
  };

  /**
   * Finaliza o jogo e mostra o pop-up de resultado.
   */
  const finishGame = () => {
    setGameFinished(true);
    setShowResultPopup(true);
  };

  /**
   * Reseta o estado do estágio atual.
   */
  const resetStage = () => {
    setGuess('');
    setFilteredOptions([]);
    setShowOptions(false);
    setTriedOptions(new Set());
  };

  /**
   * Valida a entrada do usuário com base no estágio atual.
   * @param input - Entrada do usuário.
   * @returns `true` se a entrada for válida, caso contrário `false`.
   */
  const validateInput = (input: string): boolean => {
    const normalizedInput = normalizeString(input);
    switch (stage) {
      case 'book':
        return books.some(book => normalizeString(book) === normalizedInput);
      case 'chapter':
        const chapterNum = parseInt(input);
        return !isNaN(chapterNum) && chapterNum > 0 && chapterNum <= chapters.length;
      case 'verse':
        const verseNum = parseInt(input);
        return !isNaN(verseNum) && verseNum > 0 && verseNum <= verses.length;
      case 'version':
        return versions.some(version => {
          const normalizedVersion = version === 'RA' ? 'ARA' : normalizeString(version);
          return normalizedVersion === normalizedInput;
        });
      default:
        return false;
    }
  };

  /**
   * Lida com o envio do formulário de palpite.
   * @param e - Evento de formulário.
   */
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (gameFinished) {
      return;
    }

    if (filteredOptions.length > 0 && !validateInput(guess)) {
      setGuess(filteredOptions[0]);
      handleOptionClick(filteredOptions[0]);
      return;
    }
    
    let guessValue = normalizeString(guess);
    let isCorrect = false;

    if (stage === 'version') {
      guessValue = guessValue === 'ra' ? 'ara' : guessValue;
      const correctValue = normalizeString(correctGuesses[stage]);
      isCorrect = guessValue === (correctValue === 'ra' ? 'ara' : correctValue);
    }
    else {
      isCorrect = guessValue === normalizeString(correctGuesses[stage]);
    }

    if (!validateInput(guess)) {
      setFeedback("Entrada inválida. Por favor, tente novamente.");
      setGuess('');
      return;
    }
    
    if (triedOptions.has(guessValue)) {
      setFeedback("Você já tentou essa opção. Por favor, tente outra.");
      setGuess('');
      return;
    }
    
    setTriedOptions(prev => new Set(prev).add(guessValue));
    setTotalAttempts(prev => prev + 1);
    setStageAttempts(prev => ({
      ...prev,
      [stage]: prev[stage] + 1
    }));


    if (isCorrect) {
      setFeedback(`Correto! ${
        stage === 'book' ? 'Livro' : 
        stage === 'chapter' ? 'Capítulo' :
        stage === 'verse' ? 'Versículo' :
        stage === 'version' ? 'Versão' : 
        'Etapa'
      } está certo.`);
      setCompletedStages([...completedStages, stage]);
      resetStage();

      if (stage === 'book') {
        const guessedBookAbbrev = getBookAbbrev(guess);
        if (guessedBookAbbrev) {
          fetchBookInfoForHint(guessedBookAbbrev, true);
        }
        setStage('chapter');
        setShowHintButton(true);
        setHintAvailable(false);
        setAttemptsUntilHint(3);
        setIsHintVisible(false);
        await fetchChapterHint();
      } else if (stage === 'chapter') {
        setHintAvailable(true);
        setShowHintButton(true);
        setAttemptsUntilHint(0);
        handleChapterGuess(parseInt(guessValue));
        setStage('verse');
        fetchVerses();
      } else if (stage === 'verse') {
        setHintAvailable(true);
        setShowHintButton(true);
        setAttemptsUntilHint(0);
        handleVerseGuess(parseInt(guessValue));
        setStage('version');
      } else {
        setHintAvailable(true);
        setShowHintButton(true);
        setAttemptsUntilHint(0);
        handleVersionGuess(guessValue);
        handleGameComplete();
        finishGame();
      }
    } else {
      setFeedback(`Incorreto. Tente novamente.`);
      
      if (stage === 'chapter') {
        handleChapterGuess(parseInt(guessValue));
        const newAttemptsUntilHint = attemptsUntilHint - 1;
        setAttemptsUntilHint(newAttemptsUntilHint);
        if (newAttemptsUntilHint <= 0) {
          setHintAvailable(true);
        }
      }
      
      if (stage === 'book') {
        const guessedBookAbbrev = getBookAbbrev(guess);
        if (guessedBookAbbrev) {
          fetchBookInfoForHint(guessedBookAbbrev, false);
        }
      }

      if (stage === 'verse') {
        handleVerseGuess(parseInt(guessValue));
      }

      if (stage === 'version') {
        handleVersionGuess(guessValue);
      }
    }

    setGuess('');
  };
  
  /**
   * Busca informações do livro para gerar dicas com base no palpite.
   * @param abbrev - Abreviação do livro.
   * @param isCorrect - Indica se o palpite foi correto.
   */
  const fetchBookInfoForHint = async (abbrev: string, isCorrect: boolean) => {
    try {
      const response = await axios.get<BookInfo>(`https://www.abibliadigital.com.br/api/books/${abbrev}`, {
        headers: {
          'Authorization': `Bearer ${ABIBLIA_DIGITAL_TOKEN}`
        }
      });
      const bookInfo = response.data;
      if (abbrev === 'sl') {
        bookInfo.author = 'Davi';
      }
      const newHintBlock: HintBlock = {
        guessedBook: bookInfo.name,
        group: bookInfo.group,
        author: bookInfo.author,
        chapters: bookInfo.chapters,
        testament: bookInfo.testament,
        isCorrect: isCorrect,
      };
      setHintBlocks(prev => [...prev, newHintBlock]);
    } catch (error) {
      console.error('Falha ao buscar informações do livro para dica:', error);
    }
  };

  /**
   * Determina a cor do campo da dica com base na precisão do palpite.
   * @param hint - Bloco de dica.
   * @param field - Campo da dica.
   * @returns Classe de cor correspondente.
   */
  const getHintFieldColor = (hint: HintBlock, field: keyof HintBlock) => {
    if (!bookInfo) return 'bg-gray-400';
    
    switch (field) {
      case 'guessedBook':
        return hint.isCorrect ? 'bg-green-500' : 'bg-red-500';
      case 'group':
      case 'author':
      case 'testament':
        return hint[field] === bookInfo[field] ? 'bg-green-500' : 'bg-red-500';
      case 'chapters':
        const diff = Math.abs(hint.chapters - bookInfo.chapters);
        if (diff === 0) return 'bg-green-500';
        if (diff <= 2) return 'bg-yellow-500';
        return 'bg-red-500';
      default:
        return 'bg-gray-400';
    }
  };

/**
 * Renderiza os blocos de dica baseados nos palpites.
 * @returns Elemento JSX dos blocos de dica.
 */
const renderHintBlocks = () => {
  if (stage !== 'book' && stage !== 'chapter' && stage !== 'verse' && stage !== 'version' || hintBlocks.length === 0) return null;

  const fields: (keyof Omit<HintBlock, 'isCorrect'>)[] = ['guessedBook', 'group', 'author', 'chapters', 'testament'];

  const fieldTranslations: Record<keyof Omit<HintBlock, 'isCorrect'>, string> = {
    guessedBook: 'Livro',
    group: 'Grupo',
    author: 'Autor',
    chapters: 'Capítulos',
    testament: 'Testamento'
  };

  return (
    <div className="mt-6 space-y-4">
      <div className="grid grid-cols-5 gap-1 mb-2">
        {fields.map((field) => (
          <div key={field} className="text-center">
            <p className="text-xs font-semibold text-gray-300 uppercase">{fieldTranslations[field]}</p>
          </div>
        ))}
      </div>
      <AnimatePresence>
        {hintBlocks.map((hint, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 0.3, height: 0 }}
            animate={{ opacity: 1, scale: 1.05, height: 'auto' }}
            exit={{ opacity: 0, scale: 0.3, height: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="grid grid-cols-5 gap-1 overflow-hidden rounded-lg"
          >
            {fields.map((field, fieldIndex) => (
              <div
                key={field}
                className={`
                  ${getHintFieldColor(hint, field)} 
                  flex items-center justify-center p-2 text-white text-xs
                  ${fieldIndex === 0 ? 'rounded-l-lg' : ''} 
                  ${fieldIndex === fields.length - 1 ? 'rounded-r-lg' : ''}
                  break-words text-center
                `}
                style={{
                  minHeight: '3rem',
                  wordBreak: 'break-word',
                  hyphens: 'auto'
                }}
              >
                {field === 'chapters' && (
                  <div className="flex items-center space-x-2">
                  <span className="text-white">{hint[field]}</span>
                  {/* Adiciona a seta comparativa */}
                  {bookInfo && hint[field] !== bookInfo.chapters && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-black"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d={
                          hint[field] > bookInfo.chapters
                            ? "M5 13l7 7 7-7" // Aponta para baixo se o número de capítulos do palpite for maior
                            : "M5 7l7-7 7 7" // Aponta para cima se o número de capítulos do palpite for menor
                        }
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
                )}
                {field !== 'chapters' && hint[field]}
              </div>
            ))}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};
  
  /**
   * Renderiza o rótulo do campo de entrada com base no estágio atual.
   * @returns Elemento JSX do rótulo.
   */
  const renderInputLabel = () => {
    const stageLabel = (() => {
      switch (stage) {
        case 'book':
          return '📚 Livro:';
        case 'chapter':
          return '📑 Capítulo:';
        case 'verse':
          return '📖 Versículo:';
        case 'version':
          return '📝 Versão:';
        default:
          return 'Erro';
      }
    })();

    const attempts = stageAttempts[stage];

    return (
      <div className="flex justify-between items-center">
        <span>{stageLabel}</span>
        <span className="text-sm text-gray-400">{attempts} tentativa{attempts !== 1 ? 's' : ''}</span>
      </div>
    );
  };

  /**
   * Obtém as opções atuais baseadas no estágio.
   * @returns Array de opções.
   */
  const getCurrentOptions = () => {
    const allOptions = (() => {
      switch (stage) {
        case 'book':
          return bookOptions;
        case 'chapter':
          return chapterOptions;
        case 'verse':
          return verseOptions;
        case 'version':
          return versionOptions;
        default:
          return [];
      }
    })();

    return allOptions.filter(option => !triedOptions.has(normalizeString(option)));
  };

  /**
   * Lida com a mudança na entrada do usuário.
   * @param e - Evento de mudança.
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    
    if (stage === 'chapter' || stage === 'verse') {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }
    
    setGuess(value);
    updateFilteredOptions(value);
  };

  /**
   * Atualiza as opções filtradas com base na entrada.
   * @param value - Valor atual da entrada.
   */
  const updateFilteredOptions = (input: string) => {
    if (!input.trim()) {
      const currentOptions = getCurrentOptions();
      setFilteredOptions(currentOptions);
      setShowOptions(true);
      setSelectedOptionIndex(0);
      return;
    }

    const normalizedInput = normalizeString(input);
    const currentOptions = getCurrentOptions();
    
    const filtered = currentOptions.filter(option => {
      const normalizedOption = normalizeString(option);
      return normalizedOption.includes(normalizedInput);
    });

    setFilteredOptions(filtered);
    setShowOptions(filtered.length > 0);
    setSelectedOptionIndex(0);
  };

  /**
   * Lida com eventos de teclas na entrada.
   * @param e - Evento de teclado.
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!showOptions) {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit(e as any);
        return;
      }
      return;
    }

    const moveSelection = (direction: number) => {
      const newIndex = (selectedOptionIndex + direction + filteredOptions.length) % filteredOptions.length;
      setSelectedOptionIndex(newIndex);
      scrollToOption(newIndex);
    };

    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Tab':
        e.preventDefault();
        const direction = e.key === 'ArrowUp' ? -1 : 1;
        moveSelection(direction);

        if (!keyPressInterval) {
          const interval = setInterval(() => moveSelection(direction), 100);
          setKeyPressInterval(interval);
        }
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedOptionIndex !== -1) {
          handleOptionClick(filteredOptions[selectedOptionIndex]);
        }
        break;
    }
  };

  /**
   * Lida com o soltar de teclas na entrada.
   * @param e - Evento de teclado.
   */
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyPressInterval) {
      clearInterval(keyPressInterval);
      setKeyPressInterval(null);
    }
  };

  /**
   * Rola a opção selecionada para a visualização.
   * @param index - Índice da opção.
   */
  const scrollToOption = (index: number) => {
    if (optionsRef.current) {
      const optionElements = optionsRef.current.children;
      if (optionElements[index]) {
        optionElements[index].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  };

  /**
   * Lida com o clique em uma opção.
   * @param option - Opção selecionada.
   */
  const handleOptionClick = (option: string) => {
    setGuess(option);
    setShowOptions(false);
    setSelectedOptionIndex(-1);
  };

  useEffect(() => {
    updateFilteredOptions(guess);
  }, [stage]);

  /**
   * Abre o pop-up de resultado do jogo.
   */
  const openResultPopup = () => {
    setShowResultPopup(true);
  };

  /**
   * Limpa os dados armazenados à meia-noite e busca um novo versículo.
   */
  const clearStoredVerseAtMidnight = () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    
    const msToMidnight = tomorrow.getTime() - now.getTime();
    
    setTimeout(() => {
      localStorage.removeItem('dailyVerse');
      localStorage.removeItem('dailyVerseTimestamp');
      localStorage.removeItem('dailyVerseDate');
      fetchDailyVerse();
      clearStoredVerseAtMidnight();
    }, msToMidnight);
  };

  /**
   * Lida com a conclusão do jogo, atualizando a pontuação.
   */
  const handleGameComplete = async () => {
    if (!gameFinished) {
      setGameFinished(true);
      setShowCouponPopup(true);
      
      const totalAttempts = stageAttempts.book + stageAttempts.chapter + 
                           stageAttempts.verse + stageAttempts.version;
      
      try {
        const token = localStorage.getItem('token') || DEFAULT_ACCESS_TOKEN;
        
        const coupon_response = await fetch(`${APP_API_URL}/api/coupon_create/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });

        const data = await coupon_response.json();

        if (data.cupom) {
          setCoupon(data.cupom);
          setShowCouponPopup(true);
        }
        const response = await fetch(`${APP_API_URL}/api/update_game_score/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify({
            total_attempts: totalAttempts,
          }),
        });
        
        
        await fetch(`${APP_API_URL}/api/register-daily-completion/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          }
        });

        if (response.ok) {
          // setShowResultPopup(true);
          if (data.status === 'success') {
            setFeedback('Parabéns! Seu streak foi incrementado!');
          } else if (data.status === 'already_played') {
            setFeedback('Você já jogou hoje.');
          }
        } else {
          // console.error('Erro ao atualizar pontuação:', data);
          // setFeedback('Houve um erro ao atualizar sua pontuação.');
        }
        
      } catch (error) {
        // console.error('Erro ao enviar pontuação:', error);
        // setFeedback('Houve um erro ao enviar sua pontuação.');
      }
    }
  };

  /**
   * Busca a dica do capítulo, se disponível.
   */
  const fetchChapterHint = async () => {
    if (!dailyVerse) return;
    
    if (dailyVerse.chapter_hint) {
      setChapterHint(dailyVerse.chapter_hint);
    } else {
      console.error('Dica do capítulo não disponível');
    }
  };

  /**
   * Renderiza a dica do capítulo com botão de visibilidade.
   * @returns Elemento JSX da dica do capítulo.
   */
  const renderChapterHint = () => {
    if ((stage === 'chapter' || completedStages.includes('chapter')) && showHintButton) {
      return (
        <div className="mt-4 flex items-center space-x-4">
          <button
            onClick={() => setIsHintVisible(true)}
            disabled={!hintAvailable}
            data-tooltip-id="hint-tooltip"
            data-tooltip-content={!hintAvailable ? `Disponível em ${attemptsUntilHint} tentativa${attemptsUntilHint !== 1 ? 's' : ''}` : 'Clique para ver a dica'}
            className={`p-2 rounded-full transition-all duration-300 ${
              hintAvailable 
                ? 'bg-yellow-500 hover:bg-yellow-400 text-white' 
                : 'bg-gray-600 cursor-not-allowed'
            }`}
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-6 w-6" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" 
              />
            </svg>
          </button>
          <Tooltip id="hint-tooltip" />
          
          {isHintVisible && chapterHint && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="flex-1 p-4 bg-gray-700 rounded-lg shadow-lg"
            >
              <div className="flex items-center space-x-2">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-5 w-5 text-yellow-500 flex-shrink-0" 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path 
                    fillRule="evenodd" 
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" 
                    clipRule="evenodd" 
                  />
                </svg>
                <p className="text-sm text-gray-300 italic">
                  {chapterHint}
                </p>
              </div>
            </motion.div>
          )}
        </div>
      );
    }
    return null;
  };

  /**
   * Renderiza a barra de dica baseada na tentativa do versículo.
   * @param attempt - Tentativa do versículo.
   * @returns Elemento JSX da barra de dica.
   */
  const renderVerseHintBar = (attempt: VerseAttempt) => {
    let bgColor = 'bg-gray-400';
    let arrow = null;
  
    switch (attempt.status) {
      case 'correct':
        bgColor = 'bg-green-500';
        break;
      case 'close':
        bgColor = 'bg-yellow-500';
        break;
      case 'incorrect':
        bgColor = 'bg-red-500';
        break;
      default:
        bgColor = 'bg-gray-400';
    }
  
    if (attempt.direction === 'up') {
      arrow = (
        <svg
          className="w-4 h-4 text-white ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
        </svg>
      );
    } else if (attempt.direction === 'down') {
      arrow = (
        <svg
          className="w-4 h-4 text-white ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      );
    }
  
    return (
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 0.5 }}
        className={`mt-2 flex items-center ${bgColor} rounded h-8`}
      >
        <span className="ml-2 text-xs text-white">
          {attempt.guess}
        </span>
        {arrow}
      </motion.div>
    );
  };

  /**
   * Renderiza a barra de dica baseada na tentativa do capítulo.
   * @param attempt - Tentativa do capítulo.
   * @returns Elemento JSX da barra de dica.
   */
  const renderChapterHintBar = (attempt: ChapterAttempt) => {
    let bgColor = 'bg-gray-400';
    let arrow = null;
  
    switch (attempt.status) {
      case 'correct':
        bgColor = 'bg-green-500';
        break;
      case 'close':
        bgColor = 'bg-yellow-500';
        break;
      case 'incorrect':
        bgColor = 'bg-red-500';
        break;
      default:
        bgColor = 'bg-gray-400';
    }
  
    if (attempt.direction === 'up') {
      arrow = (
        <svg
          className="w-4 h-4 text-white ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
        </svg>
      );
    } else if (attempt.direction === 'down') {
      arrow = (
        <svg
          className="w-4 h-4 text-white ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      );
    }
  
    return (
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 0.5 }}
        className={`mt-2 flex items-center ${bgColor} rounded h-8`}
      >
        <span className="ml-2 text-xs text-white">
          {attempt.guess}
        </span>
        {arrow}
      </motion.div>
    );
  };

  /**
   * Renderiza a barra de dica baseada na tentativa da versão.
   * @param attempt - Tentativa da versão.
   * @returns Elemento JSX da barra de dica.
   */
  const renderVersionHintBar = (attempt: VersionAttempt) => {
    let bgColor = attempt.status === 'correct' ? 'bg-green-500' : 'bg-red-500';
  
    return (
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 0.5 }}
        className={`mt-2 flex items-center ${bgColor} rounded h-8`}
      >
        <span className="ml-2 text-xs text-white">
          {attempt.guess.toUpperCase()}
        </span>
      </motion.div>
    );
  };

  /**
   * Reseta o estado do jogo para o estado inicial.
   */
  const resetGameState = () => {
    setGameFinished(false);
    setShowResultPopup(false);
    setStageAttempts({
      book: 0,
      chapter: 0,
      verse: 0,
      version: 0
    });
    setCompletedStages([]);
    setStage('book');
    setGuess('');
    setFilteredOptions([]);
    setShowOptions(false);
    setTriedOptions(new Set());
    setHintBlocks([]);
    setChapterHint('');
    setTotalAttempts(0);
    setVerseAttempts([]);
  };

  if (isMaintenanceTime()) {
    return <MaintenancePage />;
  }

  const fetchUserCoupons = async (token: string) => {
    try {
      const response = await axios.get<{ codigo: string; loja: string; valor: number; tipo: string }[]>(`${APP_API_URL}/api/user/coupons/`, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setCoupons(response.data);
    } catch (error) {
      console.error('Erro ao buscar cupons do usuário:', error);
    }
  };



  const handleCouponClick = (coupon: { codigo: string; loja: string; valor: number; tipo: string }) => {
    setSelectedCoupon(coupon);
    setShowSelectedCouponPopup(true);
  };


  const handleContinue = () => {
    setShowLoginSuggestion(false);
  };

  const handleGoogleLogin = () => {
    window.location.href = `${APP_API_URL}/accounts/google/login/?process=login`;
  };

  return (
    <div className="min-h-screen bg-gray-900 py-6 flex flex-col justify-center sm:py-12 bg-opacity-85">
      {showLoginSuggestion && (
        <LoginSuggestionCard 
          onContinue={handleContinue}
          onGoogleLogin={handleGoogleLogin}
          onClose={() => setShowLoginSuggestion(false)}
        />
      )}
      {/* <PartnershipCard /> */}
      {/* Colocar a imagem de background (bg-image no css) */}
      <div className="bg-image"></div>

      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-700 to-blue-900 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-gray-800 shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
          <div className="absolute top-4 left-4">
            <button
              onClick={() => setShowGameAlert(true)}
              className="flex flex-col items-center text-white focus:outline-none focus:ring-2 focus:ring-white rounded-lg p-2 transition-all duration-300 ease-in-out transform hover:scale-110"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" />
              </svg>
              <span className="text-white text-xs mt-1">Atenção!</span>
            </button>
          </div>

            <div className="absolute top-4 right-4">
              <button
                onClick={() => setShowTutorial(true)}
                className="flex flex-col items-center text-white focus:outline-none focus:ring-2 focus:ring-white rounded-lg p-2 transition-all duration-300 ease-in-out transform hover:scale-110"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className="text-white text-xs mt-1">Como Jogar</span>
              </button>
            </div>
            <div className="divide-y divide-gray-700">
              <div className="py-8 text-base leading-6 space-y-6 text-gray-300 sm:text-lg sm:leading-7">
                <div className="mb-8 relative inline-block mx-auto w-full mt-16 sm:mt-0">
                  <h1 className="text-3xl md:text-4xl font-extrabold text-center bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent drop-shadow-lg">
                    Versículo do Dia
                  </h1>
                  <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-[280px] md:w-[320px] h-1 bg-gradient-to-r from-blue-400 to-blue-600 rounded-full"></div>
                </div>

                <div className="mb-6 p-4 bg-gray-700/50 rounded-lg border border-blue-500/30">
                  <h2 className="text-sm text-gray-300 mb-2">
                    🎯 Tente adivinhar o livro, capítulo, versículo e versão da Bíblia deste texto!
                  </h2>
                  <p className="text-sm text-gray-300">
                    🏆 <button 
                      onClick={() => window.location.href = `${APP_API_URL}/accounts/google/login/?process=login`}
                      className="text-blue-400 font-semibold hover:text-blue-300 underline cursor-pointer focus:outline-none"
                    >
                      Faça login
                    </button> para participar do ranking e iniciar seu streak diário de descobertas bíblicas!
                  </p>
                </div>

                <div className="p-6 bg-gray-700 rounded-xl shadow-xl border border-blue-500/30">
                  <p className="italic text-xl text-center leading-relaxed text-white">
                    "{verse}"
                  </p>
                </div>

                {completedStages.map((completedStage) => (
                  <div key={completedStage} className="mt-4">
                    <div className="flex justify-between items-center">
                      <label className="font-medium text-gray-300">
                        {completedStage === 'book' ? '📚 Livro' :
                         completedStage === 'chapter' ? '📑 Capítulo' :
                         completedStage === 'verse' ? '📖 Versículo' :
                         completedStage === 'version' ? '📝 Versão' :
                         completedStage}:
                      </label>
                      <span className="text-sm text-gray-400">
                        {stageAttempts[completedStage]} tentativa{stageAttempts[completedStage] !== 1 ? 's' : ''}
                      </span>
                    </div>
                    <input
                      type="text"
                      className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                      value={
                        completedStage === 'version' && 
                        correctGuesses[completedStage].toUpperCase() === 'RA' 
                          ? 'ARA' 
                          : correctGuesses[completedStage].toUpperCase()
                      }
                      disabled
                    />
                    {completedStage === 'book' && renderHintBlocks()}
                    {completedStage === 'chapter' && (
                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="mt-2 p-4 bg-gray-700 rounded-lg shadow-lg"
                      >
                        <div className="flex items-center space-x-2">
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-5 w-5 text-yellow-500 flex-shrink-0" 
                            viewBox="0 0 20 20" 
                            fill="currentColor"
                          >
                            <path 
                              fillRule="evenodd" 
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" 
                              clipRule="evenodd" 
                            />
                          </svg>
                          <p className="text-sm text-gray-300 italic">
                            {chapterHint}
                          </p>
                        </div>
                      </motion.div>
                    )}
                  
                    {completedStage === 'chapter' && chapterAttempts.map((attempt, index) => (
                      <div key={index}>
                        {renderChapterHintBar(attempt)}
                      </div>
                    ))}

                    {completedStage === 'verse' && verseAttempts.map((attempt, index) => (
                      <div key={index}>
                        {renderVerseHintBar(attempt)}
                      </div>
                    ))}

                    {completedStage === 'version' && versionAttempts.map((attempt, index) => (
                      <div key={index}>
                        {renderVersionHintBar(attempt)}
                      </div>
                    ))}

                  </div>
                ))}

                {!gameFinished && (
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="relative">
                      <label htmlFor="guess" className="font-medium text-gray-300">
                        {renderInputLabel()}
                      </label>
                      <div className="flex mt-1">
                        <input
                          type="text"
                          id="guess"
                          className="flex-1 px-4 py-3 rounded-l-lg border-2 border-r-0 border-gray-600 bg-gray-700 text-white transition-all duration-200 focus:border-blue-500 focus:ring-0 hover:border-gray-500 placeholder-gray-400"
                          value={guess}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          onKeyUp={handleKeyUp}
                          onFocus={() => {
                            const currentOptions = getCurrentOptions();
                            setFilteredOptions(currentOptions);
                            setShowOptions(true);
                            setSelectedOptionIndex(0);
                          }}
                          onBlur={() => setTimeout(() => setShowOptions(false), 200)}
                          autoComplete="off"
                          placeholder={
                            stage === 'book' ? 'Digite o nome do livro...' :
                            stage === 'chapter' ? 'Digite o número do capítulo...' :
                            stage === 'verse' ? 'Digite o número do versículo...' :
                            'Digite a versão (NVI, ARA, ACF)...'
                          }
                        />
                        <button
                          type="submit"
                          className="px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-r-lg border-2 border-blue-600 hover:border-blue-700 transition-colors duration-200"
                        >
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-5 w-5" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M13 7l5 5m0 0l-5 5m5-5H6" 
                            />
                          </svg>
                        </button>
                      </div>

                      {showOptions && (
                        <ul 
                          ref={optionsRef}
                          className="absolute z-10 w-full bg-gray-700 border border-gray-600 rounded-md mt-1 max-h-60 overflow-auto"
                        >
                          {filteredOptions.map((option, index) => (
                            <li
                              key={index}
                              className={`px-4 py-2 cursor-pointer ${
                                index === selectedOptionIndex ? 'bg-blue-600 text-white' : 'hover:bg-gray-600'
                              }`}
                              onClick={() => handleOptionClick(option)}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    {stage === 'book' && hintBlocks.length > 0 && (
                      <div className="mt-4">
                        {renderHintBlocks()}
                      </div>
                    )}

                    {stage === 'verse' && verseAttempts.length > 0 && (
                      <div className="mt-4 space-y-2">
                        {verseAttempts.map((attempt, index) => (
                          <div key={index}>
                            {renderVerseHintBar(attempt)}
                          </div>
                        ))}
                      </div>
                    )}
                    {stage === 'chapter' && renderChapterHint()}
                    {stage === 'chapter' && chapterAttempts.length > 0 && (
                      <div className="mt-4 space-y-2">
                        {chapterAttempts.map((attempt, index) => (
                          <div key={index}>
                            {renderChapterHintBar(attempt)}
                          </div>
                        ))}
                      </div>
                    )}
                    {stage === 'version' && versionAttempts.length > 0 && (
                      <div className="mt-4 space-y-2">
                        {versionAttempts.map((attempt, index) => (
                          <div key={index}>
                            {renderVersionHintBar(attempt)}
                          </div>
                        ))}
                      </div>
                    )}
                  </form>
                )}
                
                {feedback && (
                  <p className="mt-2 text-sm text-blue-400">{feedback}</p>
                )}
                {/* Modal para Exibir o Desempenho */}
                {gameFinished && (
                  <div className="mt-4 text-center">
                    <button 
                      onClick={() => setShowResultPopup(true)}
                      className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-xl"
                    >
                      Desempenho e Reflexão
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Popup de cupom temporário se pa */}
      <CouponPopup
        isOpen={showCouponPopup}
        onClose={() => setShowCouponPopup(false)}
        coupon={coupon!}
      />
      <div className="mt-8">
        {isAuthenticated ? (
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full mx-auto border border-gray-700">
            <h3 className="text-lg font-semibold mb-4 text-blue-400 text-center">Meus Cupons</h3>
            {coupons.length > 0 ? (
              <table className="table-auto w-full text-gray-300">
                <thead>
                  <tr className="bg-gray-700">
                    <th className="px-4 py-2 text-left">Código</th>
                    <th className="px-4 py-2 text-left">Loja</th>
                    <th className="px-4 py-2 text-left">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {coupons.map((coupon, index) => (
                    <tr
                      key={index}
                      className="hover:bg-blue-700/40 hover:text-white cursor-pointer transition-all duration-200"
                      onClick={() => handleCouponClick(coupon)}
                    >
                      <td className="px-4 py-2 border-t border-gray-700">{coupon.codigo}</td>
                      <td className="px-4 py-2 border-t border-gray-700">{coupon.loja}</td>
                      <td className="px-4 py-2 border-t border-gray-700">
                        {coupon.valor}{coupon.tipo === 'percentual' ? '%' : ' R$'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-300 text-center">Você ainda não possui cupons.</p>
            )}
          </div>
        ) : (
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full mx-auto border border-gray-700 text-center">
            <p className="text-gray-300">Crie uma conta para jogar e conseguir cupons.</p>
          </div>
        )}
      </div>

      <CouponPopup
        isOpen={showSelectedCouponPopup}
        onClose={() => setShowSelectedCouponPopup(false)}
        coupon={selectedCoupon!}
      />

      <GameResultPopup
        isOpen={showResultPopup}
        onClose={() => setShowResultPopup(false)}
        dailyVerse={dailyVerse!}
        attempts={stageAttempts}
        totalAttempts={totalAttempts}
      />

      <TutorialPopup
        isOpen={showTutorial}
        onClose={() => setShowTutorial(false)}
      />

      <GameAlertPopUp
        isOpen={showGameAlert}
        onClose={() => setShowGameAlert(false)}
      />

      {gameFinished && (
        <div className="mt-6 text-center">
          <p className="text-lg font-semibold text-gray-400">Próximo versículo em:</p>
          <p className="text-4xl font-bold text-blue-500 mt-2">{timeUntilMidnight}</p>
        </div>
      )}
    </div>
  );
}
