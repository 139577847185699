import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { IoClose } from 'react-icons/io5'; // Ícone de fechar

interface LoginSuggestionCardProps {
  onContinue: () => void;
  onGoogleLogin: () => void;
  onClose: () => void; // Nova prop para fechar
}

const LoginSuggestionCard: React.FC<LoginSuggestionCardProps> = ({ onContinue, onGoogleLogin, onClose }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-screen h-screen bg-black bg-opacity-75 flex justify-center items-center z-50 overscroll-none">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto relative border border-gray-700 mx-4">
        {/* Botão de fechar no canto superior direito */}
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-400 hover:text-white focus:outline-none"
        >
          <IoClose size={24} />
        </button>

        <h3 className="text-2xl font-semibold mb-4 text-white text-center">
          Crie uma conta para participar do ranking e promoções!
        </h3>
        <p className="text-gray-300 text-center mb-6">
          Jogue, participe do ranking e aproveite promoções exclusivas que apoiam o projeto.
        </p>
        <button 
          onClick={onGoogleLogin}
          className="w-full flex items-center justify-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-xl mb-4"
        >
          <FcGoogle className="mr-2" />
          Autenticar com Google
        </button>
        <button 
          onClick={onContinue}
          className="w-full px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded-md text-xl"
        >
          Continuar deslogado
        </button>
      </div>
    </div>
  );
};

export default LoginSuggestionCard;
