import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { APP_API_URL } from '../config';

interface ChooseNicknameProps {
  onNicknameChosen: (nickname: string) => void;
  onLogout: () => void;
}

interface NicknameResponse {
  success: boolean;
  nickname?: string;
  igreja?: string;
  cidade?: string;
  estado?: string;
  data_criacao?: string;
}

export default function ChooseNickname({ onNicknameChosen, onLogout }: ChooseNicknameProps) {
  const [nickname, setNickname] = useState('');
  const [igreja, setIgreja] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(''); // Limpa os erros anteriores

    const token = localStorage.getItem('token');

    // Validações simples
    if (!nickname || !igreja || !cidade || !estado) {
      setError('Todos os campos são obrigatórios!');
      return;
    }

    try {
      const response = await axios.post<NicknameResponse>(`${APP_API_URL}/api/update_nickname/`, 
        { 
          nickname, 
          igreja, 
          cidade, 
          estado 
        }, 
        {
          headers: { Authorization: `Token ${token}` }
        }
      );
      
      if (response.data.success) {
        // Atualiza o estado do nickname e outros dados se necessário
        onNicknameChosen(response.data.nickname || '');
        
        // Aqui, você pode usar os dados retornados da API para, por exemplo, mostrar ou armazenar as informações do perfil.
        console.log(response.data); // Exibe os dados do perfil, como igreja, cidade, etc.

        navigate('/game');  // Redireciona para a página do jogo
      } else {
        setError('Falha ao atualizar o perfil. Tente novamente.');
      }
    } catch (error: any) {
      console.error('Erro detalhado:', error.response || error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('Erro ao definir nickname. Tente novamente.');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-2xl font-bold text-white mb-2">Escolha seu apelido</h2>
          <p className="text-red-500 text-sm">(Não poderá ser alterado posteriormente)</p>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <input
            type="text"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            placeholder="Seu apelido"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
          <input
            type="text"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Igreja"
            value={igreja}
            onChange={(e) => setIgreja(e.target.value)}
          />
          <input
            type="text"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Cidade"
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
          />
          <input
            type="text"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Estado"
            value={estado}
            onChange={(e) => setEstado(e.target.value)}
          />
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <div className="flex justify-between">
            <button
              type="button"
              onClick={onLogout}
              className="group relative w-1/2 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Sair
            </button>
            <button
              type="submit"
              className="group relative w-1/2 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
