import React, { useState } from 'react';
import { DailyVerse } from './Game';
import DOMPurify from 'dompurify';
import ShareResultCard from './ShareResultCard';

interface GameResultPopupProps {
  isOpen: boolean;
  onClose: () => void;
  dailyVerse: DailyVerse;
  attempts: {
    book: number;
    chapter: number;
    verse: number;
    version: number;
  };
  totalAttempts: number;
}

const GameResultPopup: React.FC<GameResultPopupProps> = ({ isOpen, onClose, dailyVerse, attempts, totalAttempts }) => {
  const [copyFeedback, setCopyFeedback] = useState(false);

  const handleCopyPix = () => {
    navigator.clipboard.writeText('pix.dailybible@gmail.com');
    setCopyFeedback(true);
    setTimeout(() => setCopyFeedback(false), 2000); // Remove o feedback após 2 segundos
  };

  if (!isOpen) return null;

  const maxAttempts = Math.max(...Object.values(attempts));

  const renderBar = (label: string, value: number) => (
    <div className="flex items-center mb-2">
      <div className="w-24 text-right mr-2 text-gray-300">{label}:</div>
      <div className="flex-grow bg-gray-700 rounded-full h-2.5">
        <div 
          className="bg-blue-500 h-2.5 rounded-full" 
          style={{ width: `${(value / maxAttempts) * 100}%` }}
        ></div>
      </div>
      <div className="ml-2 text-gray-300">{value}</div>
    </div>
  );

  const formatDevocional = (text: string) => {
    // Substitui asteriscos por tags HTML para títulos com menos espaçamento
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<h4 class="text-lg font-semibold text-blue-300 mt-3 mb-0.5">$1</h4>');
    
    // Substitui quebras de linha simples por <br>
    formattedText = formattedText.replace(/\n/g, '<br>');
    
    return formattedText;
  };

  return (
    <div 
      className="fixed top-0 left-0 right-0 bottom-0 w-screen h-screen bg-black bg-opacity-75 flex justify-center items-center z-50 overscroll-none"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto relative border border-gray-700 mx-4">
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-200 transition-colors duration-200"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="text-2xl font-bold mb-4 text-blue-400">Parabéns! Você completou o jogo!</h2>

        <div className="mb-6 p-4 bg-gray-700/50 rounded-lg border border-green-500/30">
          <h3 className="text-lg font-semibold mb-2 text-green-400">
            Apoie o projeto! 🙏
          </h3>
          <p className="text-gray-300 leading-relaxed">
            O DailyBible possui um custo mensal para manter o servidor online. Considere fazer uma doação via pix:{' '}
            <button 
              onClick={handleCopyPix}
              className="text-green-400 font-medium hover:text-green-300 transition-colors duration-200 relative"
            >
              pix.dailybible@gmail.com
              {copyFeedback && (
                <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-500 text-white text-xs py-1 px-2 rounded shadow-lg">
                  Copiado!
                </span>
              )}
            </button>
          </p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-200">Versículo do Dia:</h3>
          <p className="italic mb-2 text-gray-300">"{dailyVerse.texto}"</p>
          <p className="text-gray-300"><strong className="text-gray-200">Livro:</strong> {dailyVerse.livro_nome}</p>
          <p className="text-gray-300"><strong className="text-gray-200">Capítulo:</strong> {dailyVerse.capitulo}</p>
          <p className="text-gray-300"><strong className="text-gray-200">Versículo:</strong> {dailyVerse.numero}</p>
          <p className="text-gray-300"><strong className="text-gray-200">Versão:</strong> {dailyVerse.versao.toUpperCase() === 'RA' ? 'ARA' : dailyVerse.versao.toUpperCase()}</p>
          <p className="text-gray-300"><strong className="text-gray-200">Autor:</strong> {dailyVerse.autor}</p>
          <p className="text-gray-300"><strong className="text-gray-200">Grupo:</strong> {dailyVerse.grupo}</p>
          <p className="text-gray-300"><strong className="text-gray-200">Testamento:</strong> {dailyVerse.testamento}</p>
        </div>


        <ShareResultCard 
          attempts={attempts}
          totalAttempts={totalAttempts}
        />

        {/* Dar um espaço entre o ShareResultCard e o DevotionalPopup */}
        <div className="h-4"></div>

        {dailyVerse.devocional && (
          <div className="mb-6 p-4 bg-gray-700 rounded-lg border border-blue-500/30">
            <h3 className="text-xl font-bold mb-4 text-blue-400">Reflexão do Dia</h3>
            <div 
              className="text-gray-300 leading-relaxed whitespace-pre-line"
              dangerouslySetInnerHTML={{ 
                __html: DOMPurify.sanitize(formatDevocional(dailyVerse.devocional), {
                  ALLOWED_TAGS: ['br', 'h4'],
                  ALLOWED_ATTR: ['class']
                })
              }}
            />
          </div>
        )}

        <div className="mb-6 p-4 bg-gray-700/50 rounded-lg border border-yellow-500/30">
          <h3 className="text-lg font-semibold mb-2 text-yellow-400">
            Que tal ter seu momento com Deus agora?
          </h3>
          <p className="text-gray-300 leading-relaxed">
            Agora que você descobriu o versículo do dia, que tal reservar um tempo especial para sua própria reflexão? 
            Encontre um lugar tranquilo, leia novamente o versículo, medite sobre seu significado, escute um louvor e ore. 
            Permita que Deus fale ao seu coração através desta passagem.
          </p>
          <div className="mt-3 text-sm text-gray-400 italic">
            "Bem-aventurado o homem que não anda no conselho dos ímpios, não se detém no caminho dos pecadores, nem se assenta na roda dos escarnecedores. Antes, o seu prazer está na lei do SENHOR, e na sua lei medita de dia e de noite." - Salmos 1:1-2
          </div>
        </div>

        <button
          onClick={onClose}
          className="w-full mt-4 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200"
        >
          Fechar
        </button>
      </div>
    </div>
  );
};

export default GameResultPopup;
