import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import ScoreSystemModal from './ScoreSystemModal';
import { APP_API_URL } from '../config';
import ChristmasDecorations from './ChristmasDecorations';
import backgroundImage from '../assets/natal/BG_NATAL_1.png';

interface RankingPlayer {
  posicao: number;
  username: string;
  pontuacao_total: number;
  pontuacao_semanal: number;
  dias_jogados: number;
  sequencia_atual: number;
  maior_sequencia: number;
  media_pontos: number;
}

interface PaginatedRanking {
  count: number;
  next: string | null;
  previous: string | null;
  results: RankingPlayer[];
}

export default function Score() {
  const [ranking, setRanking] = useState<RankingPlayer[]>([]);
  const [currentUserRank, setCurrentUserRank] = useState<RankingPlayer | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isScoreSystemOpen, setIsScoreSystemOpen] = useState(false);

  const handleLoginRedirect = () => {
    window.location.href = `${APP_API_URL}/accounts/google/login/?process=login`;
  };

  // Verifica se o usuário está autenticado
  const isAuthenticated = localStorage.getItem('token') !== null;

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }

    const fetchRanking = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${APP_API_URL}/api/ranking/?page=${page}`, {
          headers: { Authorization: `Token ${token}` }
        });
        
        const data = response.data as PaginatedRanking;
        setRanking(data.results);
        setTotalPages(Math.ceil(data.count / 10));
        
        // Buscar posição do usuário atual
        const userResponse = await axios.get(`${APP_API_URL}/api/ranking/me/`, {
          headers: { Authorization: `Token ${token}` }
        });
        setCurrentUserRank(userResponse.data as RankingPlayer);
        
        setLoading(false);
      } catch (err) {
        setError('Erro ao carregar o ranking');
        setLoading(false);
      }
    };

    fetchRanking();
  }, [isAuthenticated, page]);

  <div 
    className="min-h-screen flex flex-col relative"
    style={{ 
      backgroundImage: `url(${backgroundImage})`, 
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed'
    }}
    >
    {/* <ChristmasDecorations /> */}
  </div>

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex justify-center items-center bg-opacity-85">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Se não estiver autenticado, mostra a tela de login
  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gray-900 flex flex-col justify-center items-center px-4 bg-opacity-85">
        <div className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-md w-full text-center">
          <h2 className="text-2xl font-bold text-white mb-6">
            Acesse o Ranking
          </h2>
          <p className="text-gray-300 mb-8">
            Faça login com o Google para acessar e participar do ranking dos jogadores.
          </p>
          <button
            onClick={handleLoginRedirect}
            className="bg-white text-gray-800 hover:bg-gray-100 px-6 py-3 rounded-full font-medium transition-colors duration-200 ease-in-out shadow-md hover:shadow-lg flex items-center justify-center space-x-2 w-full"
          >
            <svg className="w-6 h-6" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              />
              <path
                fill="currentColor"
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              />
              <path
                fill="currentColor"
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              />
              <path
                fill="currentColor"
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              />
            </svg>
            <span>Fazer login com Google</span>
          </button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex justify-center items-center bg-opacity-85">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 py-12 px-4 sm:px-6 lg:px-8 bg-opacity-85">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-extrabold text-white">
            Ranking
          </h2>
          <button
            onClick={() => setIsScoreSystemOpen(true)}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors flex items-center gap-2"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Como funciona a pontuação?
          </button>
        </div>

        {currentUserRank && (
          <div className="mb-8 bg-blue-900/50 p-6 rounded-lg border border-blue-500/30">
            <h3 className="text-lg font-semibold text-blue-400 mb-3">
              Sua Posição no Ranking
            </h3>
            <div className="flex items-center space-x-4">
              <span className="text-2xl font-bold text-white">
                #{currentUserRank.posicao}
              </span>
              <div>
                <p className="text-white font-medium">{currentUserRank.username}</p>
                <p className="text-sm text-gray-300">
                  Pontuação total: {currentUserRank.pontuacao_total.toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        )}

        {ranking.length === 0 ? (
          <div className="bg-gray-800 shadow rounded-lg p-6 text-center">
            <p className="text-gray-300">
              Ainda não há jogadores no ranking. Seja o primeiro a pontuar!
            </p>
          </div>
        ) : (
          <div className="bg-gray-800 shadow rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-gray-700">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Posição
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Jogador
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Pontuação
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Detalhes
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-800 divide-y divide-gray-700">
                  {ranking.map((player, index) => (
                    <motion.tr
                      key={player.username}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                      className="hover:bg-gray-700 transition-colors"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className={`
                            flex-shrink-0 h-8 w-8 rounded-full flex items-center justify-center
                            ${player.posicao === 1 ? 'bg-yellow-500' :
                              player.posicao === 2 ? 'bg-gray-400' :
                              player.posicao === 3 ? 'bg-yellow-700' :
                              'bg-gray-600'}
                            text-white font-bold
                          `}>
                            {player.posicao}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-white">
                          {player.username}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-white">
                          <div>Total: {player.pontuacao_total.toLocaleString()}</div>
                          {/* <div>Semanal: {player.pontuacao_semanal.toLocaleString()}</div> */}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-300">
                          <div>Dias jogados: {player.dias_jogados}</div>
                          <div>Sequência atual: {player.sequencia_atual} dias</div>
                          {/* <div>Média da semana: {player.media_pontos}</div> */}
                        </div>
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="px-6 py-4 bg-gray-700 border-t border-gray-600">
              <div className="flex justify-between items-center">
                <button
                  onClick={() => setPage(p => Math.max(1, p - 1))}
                  disabled={page === 1}
                  className={`px-4 py-2 rounded-lg ${
                    page === 1 
                      ? 'bg-gray-600 cursor-not-allowed' 
                      : 'bg-blue-600 hover:bg-blue-700'
                  } transition-colors`}
                >
                  Anterior
                </button>
                
                <span className="text-gray-300">
                  Página {page} de {totalPages}
                </span>
                
                <button
                  onClick={() => setPage(p => Math.min(totalPages, p + 1))}
                  disabled={page === totalPages}
                  className={`px-4 py-2 rounded-lg ${
                    page === totalPages 
                      ? 'bg-gray-600 cursor-not-allowed' 
                      : 'bg-blue-600 hover:bg-blue-700'
                  } transition-colors`}
                >
                  Próxima
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ScoreSystemModal
        isOpen={isScoreSystemOpen}
        onClose={() => setIsScoreSystemOpen(false)}
      />
    </div>
  );
}
